import React, { useState, useEffect,useContext } from 'react';
import Switch from '@mui/material/Switch';
import { userContext } from '../../../Contexts/useContext';
import { GoPencil } from "react-icons/go";
import { AiFillDelete } from "react-icons/ai";

const Subactivity=({task,check,page})=>{
    // const {activeComponent,setActiveComponent}=useContext(activecomponentContext)
    const [complete,setcomplete]=useState(false)
    const [ischecked,setischecked]=useState(false)
    const [checked,setChecked]=useState(ischecked)
    const user=useContext(userContext)
    const handleChange = (event) => {
        if(event.target.checked){
            setischecked(true)
            fetch(`/api/v1/completetask/${task._id}`,{method:'GET'}).then(tasks=>tasks.json()).then(tasks=>{
                check()
                return
            })
        }else if(!event.target.checked){
            setischecked(false)
            fetch(`/api/v1/incompletetask/${task._id}`,{method:'GET'}).then(tasks=>tasks.json()).then(tasks=>{
                check()

                return
            })

        }
    };
    const handleInitial=()=>{
        setischecked(task.complete)
    }
    const handleClick=()=>{

    }
    useEffect(()=>{
        handleInitial()
    },[])
    return ( 
        <div className='sub-activity'>
                     <div className='activity-details'>
                         <h5><span><AiFillDelete/> </span> {task.task} <span>  <GoPencil /></span></h5>
                         {/* <p>{task.description}</p> */}
                     </div>
                     <div className='isdone'>
                         {user && (user.role=='Engineer 1' || user.role=='Engineer 2') && <Switch 
                        checked={ischecked}
                        onChange={e=>{handleChange(e)
                                        }} 
                        
                        />}
                        {user && (user.role!=='Engineer 1' && user.role!=='Engineer 2') &&
                        <Switch 
                        checked={ischecked}
                        disabled
                        onChange={e=>{handleChange(e)
                                        }} 
                        
                        />
                        }

                     </div>
                 
                 </div>
    )
}
export default Subactivity