import ReactMapGl, {
    Marker,
    Popup,
    Source,
    Layer,
    NavigationControl,
    GeolocateControl,
  } from "react-map-gl";
  import { filterContext } from "../../../Layout";
import { useContext } from "react";
const WardSource=()=>{
    const {filters,setfilters}=useContext(filterContext)
    const featureFilter = ['==', ['get', 'NAME'],filters.ward.toUpperCase()]
    return (
        <Source
        id="my-vector-tileset"
        type="vector"
        url="mapbox://miscuit.4xkjs9tf" // Replace with your tileset URL
      >
        <Layer
          id="vector-layer"
          type="line"
          source="my-vector-tileset"
          source-layer="wards-apsw6x" // Replace with the source layer of your tileset
          paint={{
            'line-color': '#000000', // Set the color of the borders
            'line-width': 1 // Set the width of the borders
          }}
          filter={featureFilter}
        /></Source>
    )
}
export default WardSource