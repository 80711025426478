import React, { useState, useEffect,useContext } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import {siteContext,componentContext,activecomponentContext} from '../ProjectView';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
const Tabs=()=>{
    const {activeComponent, setActiveComponent} = useContext(activecomponentContext);
    const [activeStep, setActiveStep] = useState(0);
    const {siteid, setsiteid} = useContext(siteContext);
    const {components,setcomponents}=useContext(componentContext)
    const [completed, setCompleted] = useState({});
   
    const totalSteps = () => {
      return components.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            components.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step,id) => {
      setActiveStep(step);
      setActiveComponent(id)
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };

  
    return ( 
        <div className='tabs-div'>
            <div className='tabs-div1'></div>
            <div className='tabs-div2'>
            <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {components && components.map((label, index) => (
          <Step key={index} completed={completed[index]}>
            <StepButton color='green' onClick={e=>handleStep(index,label._id)}>
              <h5>{label.compname}</h5>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {(
          <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
            </div>
        </div>
    )
}
export default Tabs