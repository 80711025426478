import ReactMapGl, {
  Marker,
  Popup,
  Source,
  Layer,
  NavigationControl,
  GeolocateControl,
} from "react-map-gl";
import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react";
import SubCountySource from "./SubCountySource";
import WardSource from "./WardSource";
import Linearments from "./Linearment";
import "mapbox-gl/dist/mapbox-gl.css";
import "../Map.css";
import { villagesContext } from "../../../Layout";
import { schoolsContext } from "../../../Layout";
import { drawerContext } from "../../../Layout";
import { selectedSchool } from "../../../Layout";
import { filterContext } from "../../../Layout";
import { mapstyleContext } from "../../../Layout";
import { selectedOptionContext } from "../../../Layout";
import { linearmentContext } from "../../../Layout";
const WaterMap = () => {
  const mapRef = useRef(null);
  const {villages,setvillages}=useContext(villagesContext)
  const { style, setstyle } = useContext(mapstyleContext);
  const { filters, setfilters } = useContext(filterContext);
  const { schools, setschools } = useContext(schoolsContext);
  const [getschools, setgetschools] = useState([]);
  const [coord, setcoord] = useState(null);
  const [getcoord, setgetcoord] = useState(null);
  const { showDrawer, setshowDrawer } = useContext(drawerContext);
  const { selectedOption, setSelectedOption } = useContext(
    selectedOptionContext
  );
  const [mapcenter, setmapcenter] = useState([35.624266, -1.230503]);
  const [zoom, setzoom] = useState(8);
  const { selected, setselected } = useContext(selectedSchool);
  const {showLinearments,setshowLineearments}=useContext(linearmentContext)
  const [showpopup, setshowpopup] = useState({ index: null, show: false });
  const [viewport, setViewport] = useState({
    latitude: -1.1367309,
    longitude: 35.3244957,
    zoom: zoom,
    width: "100%",
    height: "100%",
  });
  function fetchSchools() {
    fetch("/api/v1/getschoolpoints", { method: "GET", credentials: "include" })
      .then((points) => points.json())
      .then((schools) => {
        setcoord(schools.map.features);
        setgetcoord(schools.map.features);
        setgetschools(schools.schools);
        setschools(schools.schools);
      });
  }
  const handleViewportChange = (newViewport) => {
    setViewport({ ...viewport, ...newViewport.viewState });
  };

  function filterSchools(body) {
    if (getcoord) {
      let filteredcoord = getcoord.filter((sch) => {
        if (body.ward) {
          return sch.properties.ward === body.ward;
        }
        if (body.scounty) {
          return sch.properties.scounty === body.scounty;
        }
        if (body.intervention) {
          return sch.properties.intervention === body.intervention;
        }
        if (Object.keys(body).length < 1) {
          return sch;
        }
      });
      setcoord(filteredcoord);
    }
    if (schools) {
      let filteredschools = getschools.filter((sch) => {
        if (body.ward) {
          return sch.ward === body.ward;
        }
        if (body.scounty) {
          return sch.scounty === body.scounty;
        }
        if (body.intervention) {
          return sch.intervention === body.intervention;
        }
        if (Object.keys(body).length < 1) {
          return sch;
        }
      });
      setschools(filteredschools);
    }
  }
  function flyToSchool(schoolname) {
    if (schoolname) {
      let sch = schools.filter((school) => school.name == schoolname);
      setmapcenter([sch[0].long, sch[0].lat]);
      mapRef.current?.flyTo({ center: [sch[0].long, sch[0].lat], zoom: 17 });
    } else {
      setmapcenter([35.3244957, -1.1367309]);
      mapRef.current?.flyTo({ center: [35.3244957, -1.1367309], zoom: 8 });
    }
  }
  const featureFilter = ["==", ["get", "property_name"], "property_value"];
  useEffect(() => {
      fetchSchools();
  }, []);
  useEffect(() => {}, [coord]);
  useEffect(() => {}, [style]);
  useEffect(() => {
    if (selected) {
      flyToSchool(selected.label);
    }
  }, [selected]);
  useEffect(() => {
    filterSchools(filters);
    // flyToSchool('cleared')
  }, [filters]);
  return (
    <ReactMapGl
      {...viewport}
      onMove={handleViewportChange}
      mapboxAccessToken={
        "pk.eyJ1IjoibWlzY3VpdCIsImEiOiJja3R6dXNrb2QwNHp4MnZvMXRqZGZyemN5In0.kRmmpYfd_OQ0BaAFK8u8pw"
      }
      mapStyle={style}
      ref={mapRef}
    >
      {filters.ward && <WardSource />}
      {filters.scounty && <SubCountySource />}
      {showLinearments &&<Linearments/>}
      <NavigationControl position="bottom-left" />
      <GeolocateControl position="bottom-left" />
      <div className="mapheading">
        <div
          className="select-map-item"
          onClick={(e) => {
            setshowDrawer({ open: true, purpose: "mapsettings" });
          }}
        >
          <p className="map-heading">
            Distribution of Public Primary Schools in Narok Classified Based on
            Nature Of Water Source.
          </p>
        </div>
      </div>
      <div className="selectmap">
        <div
          className="select-map-item"
          onClick={(e) => {
            setshowDrawer({ open: true, purpose: "mapsettings" });
          }}
        >
          <p className="map-settings">Open Map Settings</p>
        </div>
        <div className="select-map-item">
          <div className="blobintervention"></div>
          <p>Partner Intervention Site</p>
        </div>
        <div className="select-map-item">
          <div className="blobUnimproved"></div>
          <p>Unimproved Water Source</p>
        </div>
        <div className="select-map-item">
          <div className="blobImproved"></div>
          <p>Improved Water Source</p>
        </div>
      </div>

      {coord &&
        coord.map((coord, index) => (
          <div key={coord.id}>
            <Marker
              latitude={coord.geometry.coordinates[1]}
              longitude={coord.geometry.coordinates[0]}
            >
              <div
                className="marker"
                onMouseEnter={() =>
                  setshowpopup({ index: coord.id, show: true })
                }
                onMouseLeave={() => setshowpopup({ index: null, show: false })}
              >
                {!coord.properties.isIntervention && (
                  <div
                    className={`blob${coord.properties.classification}`}
                    onClick={(e) => {
                      flyToSchool(coord.properties.name);
                      setSelectedOption({
                        label: coord.properties.name,
                        value: coord.properties.value,
                        population: coord.properties.population,
                        girls: coord.properties.girls,
                        boys: coord.properties.boys,
                        borehole: coord.properties.water,
                      });
                      // setmapcenter([
                      //   coord.geometry.coordinates[0],
                      //   coord.geometry.coordinates[1],
                      // ]);
                      // setzoom(17);
                    }}
                  ></div>
                )}
                {coord.properties.isIntervention && (
                  <div
                    className="blobintervention"
                    onClick={(e) => {
                      flyToSchool(coord.properties.name);
                      setSelectedOption({
                        label: coord.properties.name,
                        value: coord.properties.value,
                        population: coord.properties.population,
                        girls: coord.properties.girls,
                        boys: coord.properties.boys,
                        borehole: coord.properties.water,
                      });
                    }}
                  ></div>
                )}
              </div>
            </Marker>
            {showpopup.index == coord.id && (
              <Popup
                key={index}
                longitude={coord.geometry.coordinates[0]}
                latitude={coord.geometry.coordinates[1]}
                anchor="bottom"
                offsetTop={-160}
                onClose={() => setshowpopup(false)}
              >
                <div>
                  {coord.properties.isIntervention && (
                    <p className="schoolnamepopup">{coord.properties.name}</p>
                  )}
                  {!coord.properties.isIntervention && (
                    <p>{coord.properties.name}</p>
                  )}

                  {coord.properties.isIntervention && (
                    <p>
                      This is an intervention site for{" "}
                      <strong>Amref Health Africa (Well:Fair Project)</strong>
                    </p>
                  )}
                </div>
              </Popup>
            )}
            )
          </div>
        ))}
    </ReactMapGl>
  );
};
export default WaterMap;
