import React, { useState, useEffect,useContext } from 'react';
import { styled } from '@mui/material/styles'
import { AiFillCheckCircle,AiFillWarning } from "react-icons/ai";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Subactivity from './Subactivity';
import { GoPencil } from "react-icons/go";
import { LuMilestone } from "react-icons/lu";
import { MdDeleteOutline } from "react-icons/md";

const Item=({section,page})=>{
    const [tasks,settasks]=useState(null)
    const [expanded,setexpanded]=useState(true)
    const [completedtasks,setcompletedtasks]=useState(section.sectioncomplete)
    function fetchTasks(){
      fetch(`/api/v1/gettasks/${section._id}`,{method:'GET'}).then(tasks=>tasks.json()).then(tasks=>{
        settasks(tasks.tasks)
      })
    }
    const checkSectionCompletion=()=>{
      fetch(`/api/v1/checkcompletesection/${section._id}`,{method:'GET'}).then(sect=>sect.json()).then(sect=>{
        if(sect.complete){
          setcompletedtasks(true)
        }
        else{
          setcompletedtasks(false)
        }
      })
    }
    const AccordionMine = styled(Accordion)(({ theme }) => ({
        // padding: theme.spacing(2),
        boxShadow:'none',
        paddingLeft:0,
        paddingRight:0,
        paddingTop:0
      }));
      const AccordionSummaryMine = styled(AccordionSummary)(({ theme }) => ({
        padding: theme.spacing(2),
        border: '1px solid #D1D1D1',
        backgroundColor:'#F3F3F3',
        marginLeft:0,
        marginRight:0,
        height:'30px'
      }));
      useEffect(()=>{
        fetchTasks()
        checkSectionCompletion()
    },[section])
    useEffect(()=>{
      fetchTasks()
      checkSectionCompletion()
    },[completedtasks])
    return ( 
      <>
        {page=='taskview' && <div className='main-item'>
               <AccordionMine expanded={expanded} onChange={e=>setexpanded(!expanded)}>
               <AccordionSummaryMine
                 expandIcon={<ExpandMoreIcon />}
                 aria-controls="panel1a-content"
                 id="panel1a-header"
               >
                 {section && <Typography><h5 className='heading-tab'>{section.sectionname} - Due On: <strong className='due-date'>{new Date(section.end).toDateString()}</strong></h5>
                 </Typography>}
               </AccordionSummaryMine>
               <AccordionDetails sx={{'overflow':'scroll'}}>
                 {tasks && tasks.sort((a,b)=>a.taskOrder-b.taskOrder).map((tasks,taskindex)=>(
                     <Subactivity task={tasks} key={taskindex} check={checkSectionCompletion}/>
                 ))
                 }
               </AccordionDetails>
             </AccordionMine>
           
        </div>}
        {page=='sitesetup' && <div className='main-item'>
               <AccordionMine expanded={expanded} onChange={e=>setexpanded(!expanded)}>
               <AccordionSummaryMine
                 expandIcon={<ExpandMoreIcon />}
                 aria-controls="panel1a-content"
                 id="panel1a-header"
               >
                 {section && <Typography><h5 className='heading-tab'><span><LuMilestone/> Milestone</span> - {section.sectionname}<span> <GoPencil /></span></h5>
                 </Typography>}
               </AccordionSummaryMine>
               <AccordionDetails sx={{'overflow':'scroll'}}>
                 {tasks && tasks.sort((a,b)=>a.taskOrder-b.taskOrder).map((tasks,taskindex)=>(
                     <Subactivity task={tasks} key={taskindex} check={checkSectionCompletion} page={'sitesetup'}/>
                 ))
                 }
               </AccordionDetails>
             </AccordionMine>
           
        </div>}
      </>
    )
}
export default Item