import React, { useState, useEffect, useContext } from "react";
import { BsMicrosoft } from "react-icons/bs";
import logo from "../../Images/wellfairdark.png";
import { userContext } from "../../Contexts/useContext";
import { Link } from "react-router-dom";
import Select from 'react-select' 
import { pageContext } from "../../Layout";
import "./Homepage.css";
const Homepage = () => {
  const user = React.useContext(userContext);
  const { page, setpage } = useContext(pageContext);
//   function signin() {
//     window.location.href = "/signin";
//   }
useEffect(()=>{
  setpage(window.location.pathname)
},[])
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#d8d8d8',
      borderRadius:'7px',
      minHeight: '47px',
      height: '37px',
      width:'95%',
      fontSize:'13px',
      marginBottom:'15px',
      verticalAlign:'center',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      padding: '0 6px',
      textAlign:'left',
      fontFamily:'Poppins'
      
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      textAlign:'left',
      height:'37px'
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '100%',
    }),
  };
  let options=[{label:'Amref Health Africa',value:'Amref'}]
  return (
    <div className="home">
      <div className="homecontainer">
        <div className="home1"></div>
        <div className="home2">
          <div className="logo-div-homepage">
            <img src={logo}></img>
          </div>
          <div className="sigin-div-homepage">
            {/* <h4 className="headertext">Organizational Sign In</h4> */}
            <div className="login-form">
            {/* <Select 
        options={options} 
        placeholder='Select Organization'
        defaultValue={'Amref'}
        // onChange={setSelectedOption}
        styles={customStyles}
        // menuIsOpen='true'
        isClearable='true'
        // isLoading={loading}
        />
              <input
                className="textinput-login"
                type="email"
                placeholder="Username or email"
                onChange={(e) => {
                }}
              />
              <input
                className="textinput-login"
                type="password"
                placeholder="Password"
                onChange={(e) => {
                }}
              />
              <div className="submit-button-signin">
                <p>Signin</p>
              </div> */}
              <Link to='/water-access'>
              <div className="dashboard-button-signin" onClick={e=>setpage('/water-access')}>
                <p>Proceed As Guest</p>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Homepage;
