import ReactMapGl, {
    Marker,
    Popup,
    Source,
    Layer,
    NavigationControl,
    GeolocateControl,
  } from "react-map-gl";
  import { useContext } from "react";
  import { filterContext } from "../../../Layout";
const SubCountySource=()=>{
    const {filters,setfilters}=useContext(filterContext)
    const featureFilter = ['==', ['get', 'ADM2_EN'], filters.scounty=='Transmara West'?'Kilgoris':filters.scounty]
    return (
        <Source
        id="my-vector-tileset"
        type="vector"
        url="mapbox://miscuit.5brd2km9" // Replace with your tileset URL
      >
        <Layer
          id="vector-layer"
          type="line"
          source="my-vector-tileset"
          source-layer="scounties-ccjh5f" // Replace with the source layer of your tileset
          paint={{
            'line-color': '#000000', // Set the color of the borders
            'line-width': 1 // Set the width of the borders
          }}
          filter={featureFilter}
        /></Source>
    )
}
export default SubCountySource