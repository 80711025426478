import React, { useState, useEffect,useContext,createContext} from 'react';
import { useParams } from 'react-router';
import './Project.css'
import Tabs from './Tabbed/Tabs';
import { userContext } from '../../Contexts/useContext';
import Sidemenu from './Sidemenu/Sidemenu';
import Mainview from './Main/Main';
import GanttChart from './Gannt';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
export const tasksContext=createContext()
export const activecomponentContext=createContext()
export const componentContext=createContext()
export const siteContext=createContext()
const Project=()=>{
  const user=React.useContext(userContext)
  const [components,setcomponents]=useState(null)
  const [view,setview]=useState('gantt')
  const [currentsite,setcurrentsite]=useState('')
  const [viewmode,setviewmode]=useState('Week')
  const viewmodes=["Hour", "Quarter Day", "Half Day", "Day", "Week", "Month", "Year"]
  function fetchComponents(){
    fetch(`/api/v1/fetchcomponents/${siteid}`,{method:'GET'}).then(comp=>comp.json()).then(comp=>{
      let mycomponents=comp.comp.sort((a,b)=>a.componentOrder-b.componentOrder)
      setcomponents(mycomponents)
      setActiveComponent(comp.comp[0]._id)
    })
  }
  function fetchSite(){
    fetch(`/api/v1/getsinglesite/${siteid}`,{method:'GET'}).then(site=>site.json()).then(site=>{
      setcurrentsite(site.site)
    })
  }
  const params= useParams()
  const [siteid,setsiteid]=useState(params.id)
   const switchToGant=()=>{
     setview('gantt')
   }
    const [activeComponent,setActiveComponent]=useState(false)
    useEffect(()=>{
      fetchComponents()
      fetchSite()
    },[])
    return(
        <activecomponentContext.Provider value={{activeComponent,setActiveComponent}}>
        {/* <tasksContext.Provider value={{tasks,settasks}}> */}
        <componentContext.Provider value={{components,setcomponents}}>
        <siteContext.Provider value={{siteid,setsiteid}}>
        <div className='project-view'>
            {view=='project' &&
            <>
            <Tabs/>
            <div className='main-sidemenu'>
                <Sidemenu change={switchToGant}/>
               {activeComponent && <Mainview comp={activeComponent}/>}
            </div>
            </>}
            {view=='gantt' && 
            <div className='gantt-view'>
              <div className='gantt-div1'>
                <div className='switch-view' onClick={e=>setview('project')}>
                <p>Site settings</p>
                </div>
                <div className='gantt-sitename'>
                <h2>{currentsite.sitename}</h2>
                </div>
                <div className='select-view'>
                  <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Select View Mode</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={viewmode}
          sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}
          label="Select View Mode"
          onChange={e=>setviewmode(e.target.value)}
        >{viewmodes.map((mode,index)=>(
          <MenuItem value={ mode} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}} key={index}>{mode}</MenuItem>
        ))}
        </Select>
      </FormControl>
                </div>
              </div>
              <div className='gantt-div2'>
                <GanttChart view={viewmode}/>
              </div>
            </div>
            }
        </div>
        </siteContext.Provider>
        </componentContext.Provider>
        {/* </tasksContext.Provider> */}
        </activecomponentContext.Provider>
    )
}
export default Project