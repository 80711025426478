import { useContext, useEffect, useState } from "react";
import { pageContext } from "../../../Layout";
import RightWaterFacts from "./WaterFacts";
import RightSanitationFacts from "./SanitationFacts";
const RightFacts = () => {
  const {page,setpage}=useContext(pageContext)
  return (
    <>
    {page=='/water-access' &&
    <RightWaterFacts/>
    }
    {page=='/sanitation-coverage' &&
    <RightSanitationFacts/>
    }
    </>
  );
};
export default RightFacts;
