import React, { useState, useEffect ,useContext,createContext,useRef} from 'react';
import TopFacts from './TopFacts/FactsTop';
import MapDiv from './Map/MapDiv';
import MapFilters from './FilterPane/MapFilters';
import RightFacts from './RightFacts/SchoolFactsRight';

const Map2=()=>{
    return (
        
        <div className="map">
            <div className='map-filters-info-div'>
                <div className='map-filters-filter-pane'>
                    <MapFilters/>
                </div>
                <div className='map-filters-facts-pane'>
                    <TopFacts/>
                </div>
            </div>
            <div className='map-facts-container-div'>
            <MapDiv />
            <RightFacts/>
            </div>
        </div>
        

    )
}
export default Map2