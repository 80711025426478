import { useContext, useEffect, useState } from "react";
import { filterContext } from "../../../Layout";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { villagesContext } from "../../../Layout";
import { householdContext } from "../../../Layout";
const RightSanitationFacts=()=>{
    const {villages,setvillages}=useContext(villagesContext)
    const {households,sethouseholds}=useContext(householdContext)
    const {filters, setfilters } = useContext(filterContext);
    const [odvillages,setodvillages]=useState(10)
    const [incompletehouseholds,setincompletehouseholds]=useState(0)
    function calculateODF(){
            let odf=villages.filter(villages=>villages.completion<100)
            setodvillages(odf.length)
    }
    function calculateComplete(){
            let completehhs=households.reduce((accum,current)=>{
                return accum+current.incomplete
            },0)
            setincompletehouseholds(completehhs)
    }
    useEffect(()=>{
        calculateODF()
    },[])
    useEffect(()=>{
        calculateODF()
    },[villages])
    useEffect(()=>{
        calculateComplete()
    },[])
    useEffect(()=>{
        calculateComplete()
    },[households])
    return(
        <>
      <div className="right-facts">
          <div className="right-facts-heading">
            <h3>
              {filters.ward || "Narok"} Villages Sanitation Needs Assessment
            </h3>
          </div>
          <>
            <div className="right-facts-container">
              <div className="right-facts-right">
                <div className="right-facts-fact">
                  <h2>{odvillages}</h2>
                  <h5>
                    villages practicing <strong className="kpi-fact">OPEN DEFACATION</strong>{" "}
                  </h5>
                </div>
              </div>
              <div className="right-facts-left">
                <div className="right-facts-fact">
                  <h2>{incompletehouseholds}</h2>
                  <h5>
                    Households 
                      <strong className="kpi-fact">WITHOUT LATRINES </strong>
                  </h5>
                </div>
              </div>
            </div>
          </>
        <div className="instructions">
          <div className="insructions-div">
            <Alert severity="success" sx={{fontFamily:'Poppins',fontSize:'13px'}} className="instructions-alert">
              <AlertTitle sx={{fontFamily:'Poppins',fontSize:'13px'}}>Navigation Guide</AlertTitle>
                <p>If you are interested in data from a specific village, use the village search bar at the top left or click on a village's cluster on the map</p>
                <hr></hr>
                <p>Done with your queried data? Use the clear queries buttom at the top right side to revert back to initial overall scope view</p>
                <hr></hr>
                <p>Government officials can export digital Form As and Form Bs by clicking on export data on the map legend</p>
            <hr></hr>
            </Alert>
          </div>
        </div>
      </div>
    </>
    )
}
export default RightSanitationFacts