import {
    Source,
    Layer,
  } from "react-map-gl";
import { useContext } from "react";
const Linearments=()=>{
    return (
        <Source
        id="my-vector-tileset"
        type="vector"
        url="mapbox://miscuit.3h54p896" // Replace with your tileset URL
      >
        <Layer
          id="vector-layer"
          type="line"
          source="my-vector-tileset"
          source-layer="L-dsdz9s" // Replace with the source layer of your tileset
          paint={{
            'line-color': '#0aa3a3', // Set the color of the borders
            'line-width': 1 // Set the width of the borders
          }}
        /></Source>
    )
}
export default Linearments