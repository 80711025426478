import { selectedSchool } from "../../../Layout"
import { schoolsContext } from "../../../Layout"
import { filterContext } from "../../../Layout"
import {useState,useContext,useEffect} from 'react'
const SchoolFactsTop=()=>{
    const [percentage,setpercentage]=useState(0)
    const [pop,setpop]=useState(0)
    const {schools,setschools}=useContext(schoolsContext)
    const {selected,setselected}=useContext(selectedSchool)
    const {filters,setfilters}=useContext(filterContext)
    function getPercentage(){
        let improved=schools.filter(school=>school.classification=='Improved')
        setpercentage(Math.floor(improved.length/schools.length*100))
    }
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function getPopulation(){
        let improved=schools.filter(school=>school.classification=='Improved')
        let population=improved.reduce((accum,curr)=>{
            return accum+curr.studentpopulation
        },0)
        setpop(numberWithCommas(population))
    }
    useEffect(()=>{
        getPercentage()
        getPopulation()
    },[schools])
    useEffect(()=>{
    },[selected])
    return(
        <>
         <div className="fact">
                {(selected==null || selected=='cleared' || Array.isArray(selected)) &&
                <>
                 <div className="fact-figure">
                    <h1>{schools.length}</h1>
                </div>
                <div className="fact-narration">
                    <h5><strong className="kpi-fact">primary schools</strong> are operational in {filters.ward || filters.scounty || 'Narok W. & Transmara W.'}</h5>
                </div>
                </>
                }
                {typeof selected==='object' && selected !==null  && Object.keys(selected).length>0 &&
                <>
                 <div className="fact-figure">
                    <h1>{selected.population}</h1>
                </div>
                <div className="fact-narration">
                    <h5><strong className="kpi-fact">students</strong> attend school at {selected.label}</h5>
                </div>
                </>
                }
            </div>
            <div className="fact">
            {(selected==null || selected=='cleared' || Array.isArray(selected)) &&
                <>
                <div className="fact-figure">
                    <h1>{percentage || 0}%</h1>
                </div>
                <div className="fact-narration">
                    <h5>of the schools have access to <strong className="kpi-fact">safe drinking water.</strong></h5>
                </div>
                </>
            }
            {typeof selected==='object' && selected !==null  && Object.keys(selected).length>0 &&
                <>
                <div className="fact-figure">
                    <h1>{selected.girls}</h1>
                </div>
                <div className="fact-narration">
                    <h5> <strong className="kpi-fact">girls</strong> are enrolled at {selected.label}</h5>
                </div>
                </>
            }
            </div>
            <div className="fact">
            {(selected==null || selected=='cleared' || Array.isArray(selected)) &&
                <>
                <div className="fact-figure">
                    <h1>{pop}</h1>
                </div>
                <div className="fact-narration">
                    <h5>students have access to <strong className="kpi-fact">safe drinking water.</strong></h5>
                </div>
                </>
            }
            {typeof selected==='object' && selected !==null  && Object.keys(selected).length>0 &&
                <>
                <div className="fact-figure">
                    <h1>{selected.boys}</h1>
                </div>
                <div className="fact-narration">
                <h5> <strong className="kpi-fact">boys</strong> are enrolled at {selected.label}</h5>
                </div>
                </>
            }
            </div>
        </>
    )
}
export default SchoolFactsTop