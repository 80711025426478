import "./MapSettings.css";
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from "@mui/material/RadioGroup";
import Alert from '@mui/material/Alert';
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Switch from '@mui/material/Switch';
import { mapstyleContext } from "../../Layout";
import { useContext } from "react";
import { linearmentContext } from "../../Layout";
const MapSettings = () => {
    const {style,setstyle}=useContext(mapstyleContext)
    const {showLinearments,setshowLineearments}=useContext(linearmentContext)
    const handleChange = (event) => {
        setstyle(event.target.value);
      };
  return (
    <div className="map-settings-div">
      <div className="layer-settings">
        <h2>Basemap Settings</h2>
        <h5>Select Basemap</h5>
        <FormControl>
          <RadioGroup
          row
          sx={{fontFamily:'Poppins',fontSize:'12px'}}
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={style}
            name="radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel
              value="mapbox://styles/mapbox/streets-v12"
              control={<Radio />}
              label="Streets"
              
            />
            <FormControlLabel value="mapbox://styles/mapbox/outdoors-v12" control={<Radio />} label="Outdoors" />
            <FormControlLabel value="mapbox://styles/mapbox/light-v11" control={<Radio />} label="Light" />
            <FormControlLabel value="mapbox://styles/mapbox/dark-v11" control={<Radio />} label="Dark" />
            <FormControlLabel value="mapbox://styles/mapbox/satellite-streets-v12" control={<Radio />} label="Satellite" />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="admin-settings">
        <h2>Administrative Boundaries</h2>
        <div>
        <FormControlLabel control={<Switch defaultChecked />} label="Show Administrative Boundaries" />
    </div>
      </div>
      <div className="hydro-settings">
        <h2>Hydrogeological Resources</h2>
        <h5>Select Map Overlays</h5>
        {/* <Alert severity="info" sx={{marginBottom:'10px'}}>Login to activate the hydrogeological layers</Alert> */}
        <Alert severity="warning">Selecting several layers may affect your computer's perfomance.</Alert>
        <FormControl>
            <FormControlLabel
              value="female"
              checked={showLinearments}
              control={<Checkbox />}
              onChange={e=>{setshowLineearments(e.target.checked)}}
              label="Lenearments"
            />
            <FormControlLabel  value="male" control={<Checkbox />} label=">10m3/hr-High Ground Water Potential" />
            <FormControlLabel  value="other" control={<Checkbox />} label="6-10m3/hr-Medium Ground Water Potential" />
            <FormControlLabel  value="other" control={<Checkbox />} label="3-6m3/hr-Fair Ground Water Potential" />
            <FormControlLabel  value="other" control={<Checkbox />} label="1-3m3/hr-Poor Ground Water Potential" />
            <FormControlLabel  value="other" control={<Checkbox />} label="0-1m3/hr-Very Poor Ground Water Potential" />
        </FormControl>
      </div>
    </div>
  );
};
export default MapSettings;
