import { Link } from 'react-router-dom';
import '../../index.css';
import { AiOutlineDown } from "react-icons/ai";
import { useEffect } from 'react';
import { useState,useContext } from 'react';
import { currentTab } from './Navbar';
import { pageContext } from '../../Layout';
const NavItem = ({name,link,current}) => {
	const {currenttab,setcurrenttab}=useContext(currentTab)
	const {page,setpage}=useContext(pageContext)
	return (
		<div className={currenttab==link ?'currentitem nav-item-div':'nav-item-div'} onClick={e=>{setcurrenttab(link)
												setpage(link)}}>
			{link && <Link to={link}>
				<div className='nav-item'>{name}</div>
			</Link>}
	
		</div>
	);
};

export default NavItem;