import React from "react";
import { createContext, useState, useEffect } from "react";
import { userContext } from "./Contexts/useContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Drawer from "./components/Drawer/Drawer";
export const pageContext = createContext();
export const drawerContext = createContext();
export const mapstyleContext = createContext();
export const schoolsContext = createContext();
export const selectedSchool = createContext();
export const filterContext = createContext();
export const selectedOptionContext = createContext();
export const mapSourceContext = createContext();
export const villagesContext = createContext();
export const householdContext = createContext();
export const allVillagesContext = createContext();
export const allHouseholdsContext = createContext();
export const allDataContext = createContext();
export const linearmentContext=createContext()
const Layout = ({ children }) => {
  const [user, setuser] = React.useState(null);
  const [page, setpage] = useState("/water-access");
  //  const [user,setuser]=useState({username:'Joshua Favour',email:'Joshua.Favour@amref.org'})
  const [style, setstyle] = useState("mapbox://styles/mapbox/outdoors-v12");
  const [showLinearments,setshowLineearments]=useState(false)
  const [showDrawer, setshowDrawer] = useState({
    open: false,
    purpose: null,
    schoolid: null,
  });
  const [allhh, setallhh] = useState([]);
  const [alldata, setalldata] = useState({});
  const [schools, setschools] = useState([]);
  const [households, sethouseholds] = useState([]);
  const [selected, setselected] = useState(null);
  const [selectedOption, setSelectedOption] = useState([]);
  const [filters, setfilters] = useState({});
  const [villages, setvillages] = useState([]);
  const [allvillages, setallvillages] = useState([]);
  function logout() {
    window.location.href = "/logout";
  }
  function fetchUser() {
    fetch("/api/v1/getuser", { method: "GET", credentials: "include" })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        if (data.user != null) {
          setuser(data.user);
        } else {
          return;
        }
      });
  }
  useEffect(() => {
    fetchUser();
    console.log("layout changes");
  }, []);
  React.useEffect(() => {
    setpage(window.location.pathname);
  }, []);

  return (
    <linearmentContext.Provider value={{ showLinearments,setshowLineearments }}>
    <allDataContext.Provider value={{ alldata, setalldata }}>
      <allHouseholdsContext.Provider value={{ allhh, setallhh }}>
        <allVillagesContext.Provider value={{ allvillages, setallvillages }}>
          <householdContext.Provider value={{ households, sethouseholds }}>
            <villagesContext.Provider value={{ villages, setvillages }}>
              <selectedOptionContext.Provider
                value={{ selectedOption, setSelectedOption }}
              >
                <filterContext.Provider value={{ filters, setfilters }}>
                  <selectedSchool.Provider value={{ selected, setselected }}>
                    <schoolsContext.Provider value={{ schools, setschools }}>
                      <mapstyleContext.Provider value={{ style, setstyle }}>
                        <drawerContext.Provider
                          value={{ showDrawer, setshowDrawer }}
                        >
                          <userContext.Provider value={user}>
                            <pageContext.Provider value={{ page, setpage }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {page !== "/" && <Navbar />}
                                {children}
                                {showDrawer.open && (
                                  <Drawer
                                    purpose={showDrawer.purpose}
                                    milestoneid={showDrawer.milestoneid}
                                    milestone={showDrawer.milestone}
                                    site={showDrawer.site}
                                  />
                                )}
                              </LocalizationProvider>
                            </pageContext.Provider>
                          </userContext.Provider>
                        </drawerContext.Provider>
                      </mapstyleContext.Provider>
                    </schoolsContext.Provider>
                  </selectedSchool.Provider>
                </filterContext.Provider>
              </selectedOptionContext.Provider>
            </villagesContext.Provider>
          </householdContext.Provider>
        </allVillagesContext.Provider>
      </allHouseholdsContext.Provider>
    </allDataContext.Provider>
    </linearmentContext.Provider>
  );
};

export default Layout;
