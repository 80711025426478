import Select from 'react-select' 
import { useState,useEffect,useContext } from 'react'
import { selectedSchool } from '../../../Layout'
import { filterContext } from '../../../Layout'
import { selectedOptionContext } from '../../../Layout'
import { pageContext } from '../../../Layout'
import { villagesContext } from '../../../Layout'
const SearchSchools=()=>{
const {selected,setselected}=useContext(selectedSchool)
    const [schools,setschools]=useState([])
    const {page,setpage}=useContext(pageContext)
    const [schools2,setschools2]=useState([])
    const {villages,setvillages}=useContext(villagesContext)
    const [allvillages,setallvillages]=useState([])
    const [filteredvillages,setfilteredvillages]=useState([])
    const { filters, setfilters } = useContext(filterContext);
    const [loading,setloading]=useState(true)
const {selectedOption, setSelectedOption} = useContext(selectedOptionContext);
useEffect(()=>{},[page])
const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#d8d8d8',
      minHeight: '30px',
      height: '37px',
      fontSize:'13px',
      verticalAlign:'center',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      padding: '0 6px',
      textAlign:'left',
      fontFamily:'Poppins'
      
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      textAlign:'left',
      height:'37px'
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '100%',
    }),
  };
const populateVillages=()=>{
  if(villages.length>0){
    let v = villages.map(village => ({
      label: village.villagename,
      value: village._id
    }));
    setallvillages(v)
    setfilteredvillages(v)
    setloading(false)
  }
}
const fetchSchools=()=>{
    fetch('/api/v1/addsiteschools',{method:'GET'}).then(schools=>schools.json())
    .then(schools=>{
      setschools(schools.schools)
      setschools2(schools.schools)
      setloading(false)
    })
  }

  useEffect(()=>{
    if(page=='/water-access'){
      fetchSchools()
    }
  },[page])
  useEffect(()=>{
    populateVillages()
  },[villages])
  useEffect(()=>{
    if(schools.length>0){
      if(filters.scounty){
        let newsch=schools2.filter(s=>{return s.scounty==filters.scounty})
        setschools(newsch)
      }
      if(filters.ward){
        let newsch=schools2.filter(s=>{return s.ward==filters.ward})
        setschools(newsch)
      }
      if(filters.intervention){
        let newsch=schools2.filter(s=>{return s.intervention==filters.intervention})
        setschools(newsch)
      }
      if(Object.keys(filters).length<1){
        setschools(schools2)
      }
    }
  },[filters])
  useEffect(()=>{
    if(selectedOption){
      setselected(selectedOption)
        }
    else{
      setselected('cleared')
    }
  },[selectedOption])
    return (
      <>
       {
        page=='/water-access' && 
        <Select 
        options={schools} 
        placeholder={'Search Schools'}
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        styles={customStyles}
        // menuIsOpen='true'
        isClearable='true'
        isLoading={loading}
        />
      }
      {
        page=='/sanitation-coverage' && 
        <Select 
        options={filteredvillages} 
        placeholder={'Search Villages'}
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        styles={customStyles}
        // menuIsOpen='true'
        isClearable='true'
        isLoading={loading}
        />
      }
      </>
    )
}
export default SearchSchools