import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";
import { MdOutlineLayersClear } from "react-icons/md";
import { useContext } from "react";
import { drawerContext } from "../../../Layout";
import SearchSchools from "./SearchSchools";
import { selectedSchool } from "../../../Layout";
import { filterContext } from "../../../Layout";
import { pageContext } from "../../../Layout";
const MapFilters = () => {
  let wards = [
    "Mara",
    "Naikarra",
    "Siana",
    "Ilmotiook",
    "Kilgoris Central",
    "Keyian",
    "Angata Barikoi",
    "Shankoe",
    "Kimintet",
    "Lolgorian",
  ];
  let interventions = [
    "Borehole Drilling",
    "Borehole Rehabilitation",
    "Borehole Pipeline Extension",
    "Spring Development",
    "Spring Pipeline Extension",
  ];
  const { page, setpage } = useContext(pageContext);
  const [selectedward, setselectedward] = useState(null);
  const { filters, setfilters } = useContext(filterContext);
  const { selected, setselected } = useContext(selectedSchool);
  const [scounty, setscounty] = useState(null);
  const [intervention, setintervention] = useState(null);
  const { showDrawer, setshowDrawer } = useContext(drawerContext);
  useEffect(() => {}, [page]);
  return (
    <>
      <div className="map-filters-search-pane">
        <SearchSchools />
      </div>
      <div className="map-filters-filters">
        {page == "/water-access" && (
          <>
            <div className="map-filters-filter">
              <FormControl
                fullWidth
                size="small"
                sx={{ m: 1, minWidth: "100%" }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ fontFamily: "Cera Pro1", fontSize: "13px" }}
                >
                  Suggested Intervention
                </InputLabel>
                <Select
                  onChange={(e) => {
                    setfilters({intervention: e.target.value });
                    setintervention(e.target.value);
                    setselectedward(null)
                    setscounty(null)
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Recommended Intervention"
                  sx={{
                    fontFamily: "Cera Pro1",
                    fontSize: "13px",
                    textAlign: "left",
                  }}
                  value={intervention}
                >
                  {interventions.map((s, index) => (
                    <MenuItem
                      sx={{ fontFamily: "Cera Pro1", fontSize: "13px" }}
                      className="menuitem"
                      value={s}
                      key={index}
                    >
                      {s}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </>
        )}
        <div className="map-filters-filter">
          <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ fontFamily: "Cera Pro1", fontSize: "13px" }}
            >
              Sub County
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={scounty || ""}
              label="Sub County"
              sx={{
                fontFamily: "Cera Pro1",
                fontSize: "13px",
                textAlign: "left",
              }}
              onChange={(e) => {
                setfilters({ scounty: e.target.value });
                setscounty(e.target.value);
                setselected("cleared");
                setselectedward(null);
              }}
            >
              <MenuItem
                value={"Narok West"}
                sx={{ fontFamily: "Cera Pro1", fontSize: "13px" }}
              >
                Narok West
              </MenuItem>
              <MenuItem
                value={"Transmara West"}
                sx={{ fontFamily: "Cera Pro1", fontSize: "13px" }}
              >
                Transmara West
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="map-filters-filter">
          <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ fontFamily: "Cera Pro1", fontSize: "13px" }}
            >
              Select Ward
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedward || ""}
              label="Select Ward"
              sx={{
                fontFamily: "Cera Pro1",
                fontSize: "13px",
                textAlign: "left",
              }}
              onChange={(e) => {
                setfilters({ ward: e.target.value });
                setselectedward(e.target.value);
                setselected("cleared");
                setintervention(null)
                setscounty(null);
              }}
            >
              {wards.map((ward, index) => (
                <MenuItem
                  sx={{ fontFamily: "Cera Pro1", fontSize: "13px" }}
                  key={index}
                  value={ward}
                >
                  {ward}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="map-filters-filter">
          <div
            className="show-schools-button"
            onClick={(e) => {
              setselected("cleared");
              setfilters({});
              setscounty(null);
              setselectedward(null);
            }}
          >
            <h5>
              <MdOutlineLayersClear className="insigts-icon" /> Clear Queries
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};
export default MapFilters;
