import React, { useState, useEffect } from 'react';
import { IoArrowBackOutline,IoSettingsOutline } from "react-icons/io5";

const Sidemenu=({change})=>{
    const [dep,setdep]=useState(null)
    return( 
        <div className='side-menu'>
            <div className='sidemenu-div1'>
            <div className='switch-view' onClick={e=>change()}>
                <p><span><IoArrowBackOutline/>  </span>  Back to Project View</p>
                </div>
            <h3><span><IoSettingsOutline/> </span>Project Setup</h3>
            </div>
            <div className='sidemenu-div2'></div>
        </div>
    )
}
export default Sidemenu