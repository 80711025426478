import React, { useState, useContext, useEffect, createContext } from "react";
import { userContext } from "../../Contexts/useContext";
import "./Navbar.css";
import { pageContext } from "../../Layout";
import  homepagelogoalt from '../../Images/wellfairdark.png'
import { FaWindowClose } from "react-icons/fa";
import  NavItem  from "./NavItem";
import { Link } from "react-router-dom";
export const currentTab=createContext()
const Navbar = () => {
  const user=React.useContext(userContext)
const {page,setpage}=useContext(pageContext)
const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [currenttab,setcurrenttab]=useState('water-access')
  const handleClose = () => {
    setAnchorEl(null);
  }
  useEffect(()=>{
    setpage(window.location.pathname)
    setcurrenttab(window.location.pathname)
  },[])
	return (
		<div className={page=='homepage' ? 'navbar-alt': 'navbar'}>
			<Link to={'/'}>
			<div className='n-logo'>
				<img src={homepagelogoalt } alt='' />
			</div>
			</Link>
      <currentTab.Provider value={{currenttab,setcurrenttab}}>
			<div className='n-items'>
				<NavItem name={"Water Access"} link={"/water-access"} />
				<NavItem name={"Sanitation Coverage"} link={"/sanitation-coverage"} />
				{/* <NavItem name={"Documentation"} link={"/documantation"} /> */}
			</div>
      </currentTab.Provider>
      {window.location.pathname !== '/' && window.location.pathname !== '' &&
      <div className="user-logo">
      {/* <div className="login-button">
        <h5>Login</h5>
      </div>
      <div className="partner-register">
        <h5>Partner Registration</h5>
      </div> */}
    </div>}
      
    </div>
		
	);
};

export default Navbar;
