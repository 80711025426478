import React, { useState, useEffect,useContext } from 'react';
import Item from './Mainitems'
import { GoPencil } from "react-icons/go";
import { LuComponent } from "react-icons/lu";
import { AiFillDelete } from "react-icons/ai";

const Mainview=({comp})=>{
    const [component,setcomponent]=useState(null)
    const [sections,setsections]=useState(null)
    function fetchComponent(){
        console.log('restarting fetch');
        fetch(`/api/v1/singlecomponent/${comp}`,{method:'GET'}).then(comp=>comp.json()).then(comp=>{
            console.log('chnaged component to '+comp);
            setcomponent(comp.component)
        })
    }
    function fetchSections(){
        console.log(comp);
        fetch(`/api/v1/sections/${comp}`,{method:'GET'}).then(comp=>comp.json()).then(comp=>{
            setsections(comp.sections)
        })
    }
    useEffect(()=>{
            fetchComponent()
            fetchSections()
    },[comp])
    return( 
        <div className='main-view'>
            <div className='mainview-heading'>
                <div className='mainview-heading-componentheading'>
                <h4><span><LuComponent/> Component </span> - { component && component.compname} <span><GoPencil/></span></h4>
                </div>
                <div className='mainview-heading-deletecomponent'>
                <h4><span><AiFillDelete/> </span> Delete Component </h4>
                </div>
            </div>
            <div className='mainview-items'>
                {sections && sections.sort((a,b)=>a.sectionOrder-b.sectionOrder).map((sect,index)=>(
                    <Item section={sect} index={index} id={index} page={'sitesetup'}/>
                ))}
            </div>
            
        </div>
    )
}
export default Mainview;