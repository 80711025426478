import logo from "./logo.svg";
import "./App.css";
import Layout from "./Layout";
import Homepage from "./Pages/Homepage/Homepage";
import Drawer from "./components/Drawer/Drawer";
import MapDashboard from "./Pages/Map/Dashboard";
import Project from "./Pages/WaterAccess/ProjectView";
import Dashboard from "./Pages/WaterAccess/Dashboard";
import Sanitation from "./Pages/CLTS/Sanitation";
import { useEffect, useContext, useState, createContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Homepage />
            </Route>
            <Route exact path="/water-access">
              <MapDashboard />
            </Route>
            <Route exact path="/sanitation-coverage">
              <MapDashboard />
            </Route>
            <Route exact path="/water-access/site/:id">
              <Project />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
