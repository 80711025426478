import { useContext,useEffect,useState} from "react"
import SchoolFactsTop from "./SchoolFactsTop"
import SanitationFactsTop from "./SanitationFactsTop"
import { pageContext } from "../../../Layout"
const TopFacts=()=>{
    
    const {page,setpage}=useContext(pageContext)
    return (
        <>
        {page=='/water-access' && <SchoolFactsTop/>}
        {page=='/sanitation-coverage' && <SanitationFactsTop/>}
        </>
    )
}
export default TopFacts