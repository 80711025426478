import ReactMapGl, {
    Marker,
    Popup,
    Source,
    Layer,
    NavigationControl,
    GeolocateControl,
  } from "react-map-gl";
  import React, {
    useState,
    useEffect,
    useContext,
    useRef,
  } from "react";
  import Supercluster from "supercluster";
  import SubCountySource from "./SubCountySource";
  import WardSource from "./WardSource";
  import "mapbox-gl/dist/mapbox-gl.css";
  import "../Map.css";
  import { drawerContext } from "../../../Layout";
  import { filterContext } from "../../../Layout";
  import { mapstyleContext } from "../../../Layout";
  import { selectedOptionContext } from "../../../Layout";
  import { villagesContext } from "../../../Layout";
  import { householdContext } from "../../../Layout";
  import { allVillagesContext } from "../../../Layout";
  import { allHouseholdsContext } from "../../../Layout";
  import { allDataContext } from "../../../Layout";
  const SanitationMap = () => {
    const mapRef = useRef(null);
    const {allvillages,setallvillages}=useContext(allVillagesContext)
    const {allhh,setallhh}=useContext(allHouseholdsContext)
    const {alldata,setalldata}=useContext(allDataContext)
    const [data,setdata]=useState({})
    const {households,sethouseholds}=useContext(householdContext)
    const { style, setstyle } = useContext(mapstyleContext);
    const { filters, setfilters } = useContext(filterContext);
    const { showDrawer, setshowDrawer } = useContext(drawerContext);
    const { selectedOption, setSelectedOption } = useContext(
      selectedOptionContext
    );
    const {villages,setvillages}=useContext(villagesContext)
    const [mapcenter, setmapcenter] = useState([35.624266, -1.230503]);
    const [zoom, setzoom] = useState(10);
    const [showpopup, setshowpopup] = useState({ index: null, show: false });
    const [viewport, setViewport] = useState({
      latitude: -1.010106,
      longitude: 35.004936,
      zoom: zoom,
      width: "100%",
      height: "100%",
    });
   async function fetchVillages() {
      if(villages.length<1 && Object.keys(filters).length<1){
       return fetch("/api/v1/getallvillages", { method: "GET", credentials: "include" })
          .then((points) => points.json())
          .then((villages) => {
            setallvillages(villages.villages)
            setvillages(villages.villages)
            return
          });
      }
    }
  async  function fetchHouseholds() {
      if(households.length<1 && Object.keys(filters).length<1){
       return fetch("/api/v1/getHouseholdsCompletion", { method: "GET", credentials: "include" })
          .then((points) => points.json())
          .then((households) => {
            setallhh(households.households)
            sethouseholds(households.households)
            return
          });
      }
      }
   async function fetchVillagePoints(){
      if(Object.keys(data).length<1 && Object.keys(filters).length<1){
      return  fetch("/api/v1/getVillagePoints", { method: "GET", credentials: "include" })
            .then((points) => points.json())
            .then((villages) => {
             setdata(villages.villages)
             setalldata(villages.villages)
             return
            });
      }
    }
    const handleViewportChange = (newViewport) => {
      setViewport({ ...viewport, ...newViewport.viewState });
    };
    
    function filterVillages() {
      if (allhh.length>0) {
        let filteredhh= allhh.filter((hh) => {
          if (filters.ward) {
            return hh.ward === filters.ward;
          }
          if (filters.scounty) {
            return hh.subcounty === filters.scounty;
          }
          if (Object.keys(filters).length < 1) {
            return hh;
          }
        });
        sethouseholds(filteredhh);
      }
      if (allvillages.length>0) {
        let filteredvillages = allvillages.filter((village) => {
          if (filters.ward) {
            return village.ward === filters.ward;
          }
          if (filters.scounty) {
            return village.subcounty === filters.scounty;
          }
          if (Object.keys(filters).length < 1) {
            return village;
          }
        });
        setvillages(filteredvillages);
      }
      if(Object.keys(alldata).length>0){
        if (filters.ward) {
        let newdata={type:'FeatureCollection',
            features:alldata.features.filter(feat=>feat.properties.ward==filters.ward)
          }
          setdata(newdata)
      }
      if (filters.scounty) {
        let newdata={type:'FeatureCollection',
            features:alldata.features.filter(feat=>feat.properties.subcounty==filters.scounty)
          }
          setdata(newdata)
      }
      if (Object.keys(filters).length < 1) {
        return setdata(alldata);
      }
    }
    }
    useEffect(()=>{
      filterVillages()
    },[filters])
    function flytoVillage(schoolname) {
    //   if (schoolname) {
    //     let sch = schools.filter((school) => school.name == schoolname);
    //     setmapcenter([sch[0].long, sch[0].lat]);
    //     mapRef.current?.flyTo({ center: [sch[0].long, sch[0].lat], zoom: 17 });
    //   } else {
    //     setmapcenter([35.3244957, -1.1367309]);
    //     mapRef.current?.flyTo({ center: [35.3244957, -1.1367309], zoom: 8 });
    //   }
    }
    useEffect(() => {
      fetchVillages();
    }, [villages]);
    useEffect(()=>{
      fetchHouseholds()
    },[households])
    useEffect(()=>{
      fetchVillagePoints()
    },[data])
    useEffect(() => {}, [style]);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [hoveredFeature, setHoveredFeature] = useState(null);
    const [popupCoordinates, setPopupCoordinates] = useState(null);
    const handleFeatureClick = (event) => {
      const feature = event.features[0];
      setSelectedFeature(feature);
    };
    const handleMouseEnter = (event) => {
      const feature = event.features[0];
      setHoveredFeature(feature);
      setPopupCoordinates(event.lngLat);
    };
  
    const handleMouseLeave = () => {
      setHoveredFeature(null);
      setPopupCoordinates(null);
    };
  

  
    return (
      <ReactMapGl
        {...viewport}
        onMove={handleViewportChange}
        mapboxAccessToken={
          "pk.eyJ1IjoibWlzY3VpdCIsImEiOiJja3R6dXNrb2QwNHp4MnZvMXRqZGZyemN5In0.kRmmpYfd_OQ0BaAFK8u8pw"
        }
        mapStyle={style}
        ref={mapRef}
      >
        {filters.ward && <WardSource />}
        {filters.scounty && <SubCountySource />}
        <NavigationControl position="bottom-left" />
        <GeolocateControl position="bottom-left" />
        <div className="mapheading">
          <div
            className="select-map-item"
            onClick={(e) => {
              setshowDrawer({ open: true, purpose: "mapsettings" });
            }}
          >
            <p className="map-heading">
             Distribution of village clusters for currently tracked villages in Narok West and Transmara West.
            </p>
          </div>
        </div>
        <div className="selectmap">
          <div
            className="select-map-item"
            onClick={(e) => {
              setshowDrawer({ open: true, purpose: "mapsettings" });
            }}
          >
            <p className="map-settings">Export Data</p>
          </div>
          <div className="select-map-item">
            <div className="less25"></div>
            <p>Upto 25% latrine coverage</p>
          </div>
          <div className="select-map-item">
            <div className="less50"></div>
            <p>Upto 50% latrine coverage</p>
          </div>
          <div className="select-map-item">
            <div className="less75"></div>
            <p>Upto 75% latrine coverage</p>
          </div>
          <div className="select-map-item">
            <div className="less100"></div>
            <p>Upto 99% latrine coverage</p>
          </div>
          <div className="select-map-item">
            <div className="actual100"></div>
            <p>100% latrine coverage</p>
          </div>
        </div>
        {data &&
        <>
         <Source
        id="villages"
        type="geojson"
        data={data}
      >
        <Layer
          id="villages-layer"
          type="circle"
          paint={{
            'circle-color': ['get', 'color'],
            'circle-opacity': .6,
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['get', 'allhouseholds'],
              0, 10, // 10 pixels for 0 households
              200, 50 // 50 pixels for 1000 households
            ],
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
          }}
          onClick={handleFeatureClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
         <Layer
          id="household-count"
          type="symbol"
          source="villages"
          layout={{
            'text-field': ['get', 'allhouseholds'],
            'text-font': ['Open Sans Bold'],
            'text-size': 12
          }}
          paint={{
            'text-color': '#000000'
          }}
          
        />

{hoveredFeature && popupCoordinates && (
          <Popup
            latitude={popupCoordinates[1]}
            longitude={popupCoordinates[0]}
            onClose={handleMouseLeave}
          >
            <div>
              <h3>{hoveredFeature.properties.villagename}</h3>
              <p>Completion: {hoveredFeature.properties.completion}%</p>
            </div>
          </Popup>
        )}
      </Source>
        </>
        }

      
      </ReactMapGl>
    );
  };
  export default SanitationMap;
  