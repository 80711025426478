import {useState,useEffect,useContext} from 'react'
import { villagesContext } from '../../../Layout'
import { filterContext } from '../../../Layout'
import { householdContext } from '../../../Layout'
const SanitationFactsTop=()=>{
    const {households,sethouseholds}=useContext(householdContext)
    const {filters,setfilters}=useContext(filterContext)
    const {villages,setvillages}=useContext(villagesContext)
    const [odfvillages,setodfvillages]=useState(10)
    const [completehouseholds,setcompletehouseholds]=useState(0)
    function calculateODF(){
            let odf=villages.filter(villages=>villages.completion==100)
            setodfvillages(odf.length)
    }
    function calculateComplete(){
        if(households.length>0){
            let completehhs=households.reduce((accum,current)=>{
                return accum+current.completeHH
            },0)
            setcompletehouseholds(completehhs)
        }
        else{
            setcompletehouseholds(0)
        }
    }
    useEffect(()=>{
        calculateODF()
    },[villages])
    useEffect(()=>{
        calculateComplete()
    },[households])
    
    return (
        <>
         <div className="fact">
                <>
                 <div className="fact-figure"><h1> 
                    {villages.length}</h1>
                </div>
                <div className="fact-narration">
                    <h5><strong className="kpi-fact">villages</strong> currently being tracked in {filters.ward || 'Narok County'}</h5>
                </div>
                </>
            </div>
            <div className="fact">
                <>
                <div className="fact-figure">
                    <h1>{odfvillages}</h1>
                </div>
                <div className="fact-narration">
                    <h5>of the villages have declared <strong className="kpi-fact">open defaction free</strong></h5>
                </div>
                </>
            </div>
            <div className="fact">
                <>
                <div className="fact-figure">
                    <h1>{completehouseholds}</h1>
                </div>
                <div className="fact-narration">
                    <h5>Households have access to <strong className="kpi-fact">basic sanitation facilities.</strong></h5>
                </div>
                </>
            </div>
        </>
    )
}
export default SanitationFactsTop