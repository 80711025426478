import { useContext, useEffect, useState } from "react";
import { schoolsContext } from "../../../Layout";
import { filterContext } from "../../../Layout";
import { selectedSchool } from "../../../Layout";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
const RightWaterFacts=()=>{
    const { schools, setschools } = useContext(schoolsContext);
  const { filters, setfilters } = useContext(filterContext);
  const { selected, setselected } = useContext(selectedSchool);
  const [sch, setsch] = useState(0);
  const [pop, setpop] = useState(0);
  function getUnimproved() {
    let unimproved = schools.filter(
      (school) => school.classification == "Unimproved"
    );
    setsch(unimproved.length);
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getPopulation() {
    let unimproved = schools.filter(
      (school) => school.classification == "Unimproved"
    );
    let population = unimproved.reduce((accum, curr) => {
      return accum + curr.studentpopulation;
    }, 0);
    setpop(numberWithCommas(population));
  }
  useEffect(() => {
    getUnimproved();
    getPopulation();
  }, [schools]);

  return (
    <>
      <div className="right-facts">
        {(selected == null ||
          selected == "cleared" ||
          Array.isArray(selected)) && (
          <div className="right-facts-heading">
            <h3>
              {filters.ward || filters.scounty || "Narok"} Schools' Water Needs
              Assessment
            </h3>
          </div>
        )}
        {typeof selected === "object" &&
          selected !== null &&
          Object.keys(selected).length > 0 && (
            <div className="right-facts-heading">
              <h3>{selected.label}'s Water</h3>
            </div>
          )}
        {(selected == null ||
          selected == "cleared" ||
          Array.isArray(selected)) && (
          <>
            <div className="right-facts-container">
              <div className="right-facts-right">
                <div className="right-facts-fact">
                  <h2>{pop}</h2>
                  <h5>
                    students <strong className="kpi-fact">WITHOUT</strong>{" "}
                    access to safe drinking water
                  </h5>
                </div>
              </div>
              <div className="right-facts-left">
                <div className="right-facts-fact">
                  <h2>{sch}</h2>
                  <h5>
                    schools in{" "}
                    {filters.ward ||
                      filters.scounty ||
                      "Narok W. & Transmara W"}{" "}
                    using <strong className="kpi-fact">Unimproved </strong>
                    drinking water
                  </h5>
                </div>
              </div>
            </div>
          </>
        )}
        {typeof selected === "object" &&
          selected !== null &&
          Object.keys(selected).length > 0 && (
            <>
              <div className="right-facts-container">
                <div className="right-facts-right" style={{ width: "70%" }}>
                  <div className="right-facts-fact">
                    <h2 style={{ fontSize: "28px" }}>{selected.borehole}</h2>
                    <h5>
                      School's <strong className="kpi-fact">MAIN</strong> source
                      for drinking water
                    </h5>
                  </div>
                </div>
              </div>
            </>
          )}
        <div className="instructions">
          <div className="insructions-div">
            <Alert severity="info" sx={{fontFamily:'Poppins',fontSize:'13px'}} className="instructions-alert">
              <AlertTitle sx={{fontFamily:'Poppins',fontSize:'13px'}}>Navigation Guide</AlertTitle>
                <p>If you are interested in data from a specific school, use the school search bar at the top left or click on a school's placemark on the map</p>
                <hr></hr>
                <p>Done with your filtered data? Use the clear filters buttom at the top right side to revert back to initial overall scope view</p>
                <hr></hr>
                <p>Open the map settings to switch between layers and map styles. You will also have access to the <strong>Hydrogeomapping layers</strong> that will assist you in making intervention decisions</p>
            <hr></hr>
            </Alert>
          </div>
        </div>
      </div>
    </>
  )
}
export default RightWaterFacts