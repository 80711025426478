import { useContext } from "react";
import { pageContext } from "../../../Layout";
import WaterMap from "./WaterMap";
import SanitationMap from "./SanitationMap";
const MapDiv = () => {
  const {page,setpage}=useContext(pageContext)
  return (
      <div className="mapdiv">
        {page=='/water-access' && <WaterMap/>}
        {page=='/sanitation-coverage' && <SanitationMap/>}
      </div>
  );
};
export default MapDiv;
